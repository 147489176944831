<template>
    <h1>Conditinal Components</h1>


    <button @click="login">로그인</button>
    <div v-if="isLogined">환영합니다 고객님</div>
    <div v-else>로그인해주세요</div>
    <h1>상품목록조히</h1>
    <ul>
        <!-- v-for에서는 반드시 key 값을 지정. key를 지정함으로서 각 요소마다의 고유식별 -->
        <li v-for="product in products" :key="product.id">{{ product.name }}</li>
    </ul>
</template>

<script>
export default{
    data(){
        return{
            isLogined:false,
            products:[
                {id:1, name: "apple"},
                {id:2, name: "banana"},
                {id:3, name: "strawberry"}
            ]
        }
    },
    methods:{
        login(){
            alert(this.isLogined=true)
        }
    },
}
</script>