<template>
    <v-container>
        <v-row justify="center">
            <v-col class="text-center text-h5">
                장바구니 목록
            </v-col>
        </v-row>
        <v-row justify="end">
            <v-col cols="auto">
                <v-btn @click="orderCreate" color="primary">주문하기</v-btn>
            </v-col>
            <v-col cols="auto">
                <v-btn @click="clearCart" color="secondary">장바구니 비우기</v-btn>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-table>
                    <thead>
                        <tr>
                            <th>제품 ID</th>
                            <th>제품명</th>
                            <th>주문수량</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="product in getProductsInCart" :key="product.id">
                            <td>{{product.id}}</td>
                            <td>{{product.name}}</td>
                            <td>{{product.quantity}}</td>
                        </tr>
                    </tbody>
                </v-table>
            </v-col>
        </v-row>
    </v-container>    
</template>


<script>
import axios from 'axios';
import { mapGetters } from 'vuex';

export default{
    data() {
        return {
        }
    },
    computed:{
        ...mapGetters(['getProductsInCart'])
    },
    methods: {
        clearCart(){
            this.$store.dispatch("clearCart")
        },
        async orderCreate(){
            const orderProducts = this.getProductsInCart
            .map(p=>{
                return {productId: p.id, productCount: p.quantity};
            });
            console.log(orderProducts)

            if(orderProducts.length < 1){
                alert("주문 물품 수량이 부족합니다.")
                return;
                    }
            const yesOrNo = confirm(`${orderProducts.length}개의 상품을 주문하시겠습니가?`);
            if(!yesOrNo){
                console.log("주문이 취소되었습니다.")
                return;
            }
            try{
                await axios.post(`${process.env.VUE_APP_API_BASE_URL}/order/create`, orderProducts);
                alert("주문이 정상적으로 처리되었습니다.")
                this.clearCart()
            }catch(e){
                console.log(e);
                alert("주문이 처리되지 않았습니다.")
            }
                
        }
    },
}
</script>