<template>
    <div class="content-container m b-10"></div>

    <v-footer app class="text-center">
        <v-row class="d-flex justify-center">
            2024 copyrights. 정보책임보호자 : milcho 개발자 : milcho
        </v-row>

    </v-footer>
</template>

<script>
export default{};
</script>